import React from "react";
import { Link } from "gatsby";

export const Breadcrumb = props => {
  let breadcrumb = [];
  for (let i = 0; i < props.items.length; i++) {
    breadcrumb.push(
      <React.Fragment key={"item-" + i}>
        <span>
          <Link to={props.items[i].url}>{pathToTitle[props.items[i].url]}</Link>
        </span>
      </React.Fragment>
    );
    if (i < props.items.length - 1) {
      breadcrumb.push(<span key={"separator-" + i}> >> </span>);
    }
  }
  if (breadcrumb.length > 0) {
    return <div className="breadcrumb">{breadcrumb}</div>;
  }
  return null;
};

export const pathToTitle = {
  "/": "Etusivu",
  "/fi/excel-kurssi-netissa": "Excel-peruskurssi",
  "/fi/excel-jatkokurssi": "Excel-jatkokurssi",
};
