import { Link } from "gatsby";
import React from "react";
import { getKeyFromString } from "../utils/utils.js";
import "./footer.module.scss";

export default props => (
  <footer className="footer">
    <div className="container">
      <div className="row d-flex justify-content-between">
        <div
          key="newsletter"
          className="col-md-3 footer-block newsletter"
        >
          <div className="description mb-3">
            Tilaa uutiskirje ja vinkit Excelin ja muiden office-ohjelmien
            käyttöön.
          </div>
          <a
            href="http://eepurl.com/cSbTxn"
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tilaa uutiskirje ja vinkit
          </a>
        </div>
        <div
          key="companyinfo"
          className="col-md-3 footer-block company-data"
        >
          Kurssin on tuottanut
          <div className="company-data-title">
            <a href="https://www.officekoulutus.fi" target="_blank">
              {props.company_title}
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
