import animateScrollTo from 'animated-scroll-to';

export function getKeyFromString(str) {
  let key = str.replace(/ /g, '');
  key = key.slice(0, 50);
  return key;
}

export function compareSlashInsensitive(url1, url2) {
  // See more at: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match
  const pattern = /^\/?(?<pathNoSlashesAtBeginningOrEnd>[^/]+.+[^/]+)\/?$/;
  const url1MatchObject = url1.match(pattern);
  const url2MatchObject = url2.match(pattern);
  if (
    url1MatchObject &&
    url2MatchObject &&
    url1MatchObject.groups.pathNoSlashesAtBeginningOrEnd &&
    url2MatchObject.groups.pathNoSlashesAtBeginningOrEnd &&
    url1MatchObject.groups.pathNoSlashesAtBeginningOrEnd ===
      url2MatchObject.groups.pathNoSlashesAtBeginningOrEnd
  ) {
    return true;
  }
  return false;
}

export const executeScroll = queryString => {
  if (queryString) {
    let element = document.querySelector(queryString);
    animateScrollTo(element, {
      offset: -10,
      speed: 1500,
    });
    // Should we modify history?
    // See https://developer.mozilla.org/en-US/docs/Web/API/History_API
  }
};

// See: https://medium.com/javascript-inside/safely-accessing-deeply-nested-values-in-javascript-99bf72a0855a
export const getNestedValue = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
