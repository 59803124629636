import React from "react";
// import emergence from "emergence.js";

import { siteMetadata } from "../../gatsby-config";

import Footer from "./footer";
import Header from "./header";
import CookieConsent from "react-cookie-consent";

import "../scss/gatstrap.scss";
import "modern-normalize/modern-normalize.css";
// import "prismjs/themes/prism.css";
import '../scss/global_sass.scss';
import "animate.css/animate.css";
import "font-awesome/css/font-awesome.css";

import "../scss/global.scss";
import "../scss/testimonials.scss";

class Layout extends React.Component {

  render() {
    const { children } = this.props;
    const {
      footer: {
        company_data: { title: siteTitle, content: siteContactItems },
        services: { title: servicesTitle, content: serviceItems },
      },
    } = siteMetadata;
    return (
      <div className={"page-content"}>
        <Header {...this.props} />
        {/* <Navi title={siteMetadata.title} {...this.props} /> */}
        {children}
        <Footer
          company_title={siteTitle}
          services_title={servicesTitle}
          siteContactItems={siteContactItems}
          serviceItems={serviceItems}
        />
        <CookieConsent buttonText="OK, asia selvä!">
          Sivustomme käyttää evästeitä, jotta voisimme taata sinulle parhaan
          mahdollisen käyttökokemuksen
        </CookieConsent>
      </div>
    );
  }
}

export default Layout;
