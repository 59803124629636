import React from "react";
import styles from "./header.module.scss";
import { Breadcrumb } from "./breadcrumb";

export default ({
  additionalClassForWrapper = {},
  headerBgStyles = {},
  displayPushToBottom = false,
  headerNavLinkStyle = {},
  mainTitle,
  mainTitleClasses = "",
  additionalTitle = false,
  tagLine = false,
  headerTitleStyle = {},
  headerAdditionalTitleStyle = {},
  additionalTitleClassNames = "mb-4 mt-md-0 mb-md-6",
  logo = "",
  logoStyle = { width: "173px" },
  branding = "navbar",
  ...other
}) => {
  // return <h1 className={styles.myHeader}>{props.text}</h1>

  const pathArray = other.location.pathname.split("/");
  console.log(pathArray);
  const relevantItems = pathArray.slice(2);
  let finalItems = [];
  if (relevantItems.length > 0) {
    finalItems.push({ url: "/", title: "etusivu" });
  }
  console.log(relevantItems);
  if (relevantItems.length > 1) {
    console.log(finalItems);
    finalItems.push({
      url: "/fi/" + relevantItems[0],
      title: relevantItems[0],
    });
  }
  // Prevent breadcrumb on login page.
  if (pathArray.length > 1) {
    if (pathArray[1] === 'login') {
      finalItems = [];
    }
  }

  return (
    <div>
      <div
        className={
          styles.bg + " " + "container-fluid" + " " + additionalClassForWrapper
        }
        style={headerBgStyles}
      >
        {displayPushToBottom ? (
          // Make this div occupy as much horizontal pace as it can.
          // This is used to push site title to the bottom on the frontpage.
          <div
            style={{ flex: "1" }}
            // className="nav-scroller py-1 mb-2"
          ></div>
        ) : null}
        <header className={styles.myHeader + " " + "py-3"}>
          <div className="row flex-nowrap justify-content-between align-items-center header-row">
            <div className="col-sm-12">
              <div className="header-texts text-center">
                <h1
                  className={styles.myTitle + " " + mainTitleClasses}
                  style={headerTitleStyle}
                >
                  {mainTitle}
                </h1>
                {additionalTitle ? (
                  <div
                    className={
                      styles.additionalTitle + " " + additionalTitleClassNames
                    }
                    style={headerAdditionalTitleStyle}
                  >
                    {additionalTitle}
                  </div>
                ) : null}
                {tagLine ? (
                  <div
                    className={styles.tagline + " " + "mb-4 mt-md-4 mb-md-4"}
                  >
                    {tagLine}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </header>
      </div>
      <Breadcrumb items={finalItems} />
    </div>
  );
};
