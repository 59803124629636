require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
});

// const { pushToDataLayer } = require('./configure-tagmanager.js');
module.exports = {
  siteMetadata: {
    title: "TITLE",
    description: "Excel-kurssit työelämän tarpeisiin",
    siteUrl: "https://excel-haltuun.com",
    author: "jaakko",
    paths: {
      course_listing: "fi/koulutus",
    },
    titleTemplate: "%s | excel-haltuun.com",
    //description: 'Gatsby starter for bootstrap a blog',
    url: "https://officekoulutus.fi", // No trailing slash allowed!
    image: "/images/snape.jpg", // Path to your image you placed in the 'static' folder
    twitterUsername: "missing",
    footer: {
      company_data: {
        title: "Officekoulutus Visio",
        content: [
          { text: "info@officekoulutus.fi" },
          { text: "puh: 040 840 9799" },
          { text: "Y-tunnus: 2111512-5" },
        ],
      },
      services: {
        title: "Palvelut",
        content: [
          { text: "Excel-koulutukset", link: "fi/excel-koulutus" },
          { text: "Excel-verkkokurssi", link: "fi/excel-kurssi-netissa" },
          // { text: 'Muut office-aiheet', link: '/' },
          {
            text: "Testausautomaatio",
            link: "fi/koulutus/robot-framework-koulutus",
          },
        ],
      },
    },
  },
  plugins: [
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/content/images/`,
        name: "images",
      },
    },
    // // Tämä rivi saa ilmeisesti folderin näkymään omana osionaan
    // 'gatsby-transformer-json',
    // // see https://itnext.io/reading-data-from-a-json-file-with-gatsby-graphql-572b18ab98a
    // // Ja tämä exposaa fileet allFiles alle kaiketi?
    // {
    //   resolve: `gatsby-source-filesystem`,
    //   options: {
    //     name: `data`,
    //     path: `${__dirname}/content/courses/`,
    //   },
    // },
    "gatsby-transformer-json",
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `online-course-menu-hierarchy`,
        path: `${__dirname}/content/metadata`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `online-course-source`,
        path: `${__dirname}/content/online-course-pages`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `excel-advanced`,
        path: `${__dirname}/content/excel-advanced`,
      },
    },
    // {
    //   resolve: `gatsby-source-filesystem`,
    //   options: {
    //     name: `excel-advanced-menu`,
    //     path: `${__dirname}/content/metadata/excel-advanced-menu`,
    //   },
    // },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/content/content-snippets`,
        name: "courses",
      },
    },
    {
      resolve: "gatsby-transformer-remark",
      options: {
        plugins: [
          "gatsby-remark-copy-linked-files",
          "gatsby-plugin-catch-links",
        ],
      },
    },
    {
      resolve: "gatsby-plugin-html-attributes",
      options: {
        lang: "fi",
      },
    },
    // {
    //   resolve: 'gatsby-plugin-google-tagmanager',
    //   options: {
    //     id: 'GTM-K2V5LL',

    //     // Include GTM in development.
    //     // Defaults to false meaning GTM will only be loaded in production.
    //     includeInDevelopment: false,

    //     // datalayer to be set before GTM is loaded
    //     // should be an object or a function that is executed in the browser
    //     // Defaults to null
    //     // defaultDataLayer: pushToDataLayer,
    //   },
    // },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: "Officekoulutus",
        short_name: "Officekoulutus",
        // description: 'Gatsby starter for bootstrap a blog',
        // homepage_url: 'https://gatstrap.netlify.com',
        start_url: "/",
        background_color: "#fff",
        theme_color: "#673ab7",
        display: "standalone",
        icon: `src/images/favicon@2x.jpg`,
        // icons: [
        //   {
        //     src: '/img/android-chrome-192x192.png',
        //     sizes: '192x192',
        //     type: 'image/png',
        //   },
        //   {
        //     src: '/img/android-chrome-512x512.png',
        //     sizes: '512x512',
        //     type: 'image/png',
        //   },
        // ],
      },
    },
    // {
    //   resolve: 'gatsby-plugin-netlify',
    //   options: {
    //     mergeSecurityHeaders: true,
    //     mergeLinkHeaders: true,
    //     mergeCachingHeaders: true,
    //   },
    // },
    // 'gatsby-plugin-offline',
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sass",
    "gatsby-plugin-sharp",
    // 'gatsby-plugin-sitemap',
    // 'gatsby-plugin-twitter',
    // ks. https://www.gatsbyjs.org/packages/gatsby-transformer-sharp/
    "gatsby-transformer-sharp",
    {
      resolve: "gatsby-plugin-web-font-loader",
      options: {
        google: {
          families: ["Raleway:Regular", "Caveat"],
        },
      },
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-TN42Q29',

        // Include GTM in development.
        // Defaults to false meaning GTM will only be loaded in production.
        includeInDevelopment: false,

        // datalayer to be set before GTM is loaded
        // should be an object or a function that is executed in the browser
        // Defaults to null
        // defaultDataLayer: {},
      },
    },
    {
      resolve: "gatsby-plugin-htaccess",
      options: {
        RewriteBase: true,
        https: true,
        www: true,
        // SymLinksIfOwnerMatch: true,
        // host: 'officekoulutus.fi',
        // redirect: [
        //   'RewriteRule ^not-existing-url/?$ /existing-url [R=301,L,NE]',
        //   {
        //     from: 'my-domain.com',
        //     to: 'mydomain.com',
        //   },
        //   {
        //     from: 'my-other-domain.com',
        //     to: 'mydomain.com',
        //   },
        // ],
        // custom: `
        //     # This is a custom rule!
        //     # This is a another custom rule!
        // `,
      },
    },
  ],
};
